import { RouteAdminHandler, RouteHandler, RouteLandingHandler } from 'components/RouteHandler'
import { Route } from 'react-router-dom'
import * as pages from 'pages'
import { Switch } from 'react-router-dom'

export const navigationRoutes = {
    landing: '/',
    signUp: '/sign-up',
    signIn: '/sign-in',
    redirect: '/redirect',
    verifyDocument: '/verify',
    user: {
        base: '/user',
        home: '/user/home',
        payments: '/user/payments',
        utilityBills: '/user/utilitybills',
        correspondence: '/user/correspondence',
    },
    admin: {
        base: '/admin/view',
        addresses: '/admin/view/addresses',
    },
    adminSubscription: {
        base: '/admin/subscription',
        overview: '/admin/subscription/overview',
        correspondence: '/admin/subscription/correspondence',
        utilityBills: '/admin/subscription/utilitybills',
        sendouts: '/admin/subscription/sendouts',
    },
    orderAddress: '/order-address',
    legal: {
        base: '/legal',
        termsOfUse: '/legal/terms-of-use',
    },
}

const { landing, signUp, signIn, redirect, verifyDocument, user, admin, adminSubscription, orderAddress, legal } = navigationRoutes

const Routes = () => {
    return (
        <Switch>
            <RouteLandingHandler path={landing} exact Component={pages.Landing} />
            <RouteLandingHandler path={signUp} Component={pages.SignUp} />
            <RouteLandingHandler path={signIn} Component={pages.SignIn} />
            <Route path={`${redirect}/:signInToken`} component={pages.Redirect} />
            <Route path={`${verifyDocument}/:id`} component={pages.Verify} />
            <RouteHandler path={`${user.base}/:tabParam`} Component={pages.UserPage} />
            <RouteHandler path={orderAddress} Component={pages.OrderAddress} />
            <RouteAdminHandler path={`${admin.base}/:tabParam`} Component={pages.AdminPage} />
            <RouteAdminHandler path={`${adminSubscription.base}/:tabParam`} Component={pages.Subscription} />
            <Route path={legal.termsOfUse} component={pages.Legal} />
            <Route render={() => <h1>404 Not found</h1>} />
        </Switch>
    )
}

export default Routes
