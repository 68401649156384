import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { navigationRoutes } from 'routes/Routes'
import { Gutter } from 'styles'
import { Detail, DetailsViewAll } from 'components/shared'
import { Row, Space } from 'styles'
import { useTranslation } from 'react-i18next'

interface IProps extends IUserSubscription {}

const Details: FC<IProps> = props => {
    const { t } = useTranslation()
    const history = useHistory()

    const historyApiState = { addressId: props.address.id, addressName: props.address.name }

    return (
        <Gutter size={28} mobile equalChildSize>
            <Detail name={'Details'}>
                <>
                    <div>
                        {props.subscriber?.firstName} {props.subscriber?.lastName}
                    </div>
                    <Row>
                        <div>{props.address.street}</div>, <Space right={5} />
                        <div>{props.address.postCode}</div>
                    </Row>
                    <Row>
                        <div>{props.address.city}</div>, <Space right={5} />
                        <div>{props.address.countryCode}</div> <Space right={5} />
                        <div>{props.address.suite}</div>
                    </Row>
                </>
            </Detail>
            {props.subscriber?.registeredAddress && (
                <Detail name={'Original address'}>
                    <>
                        <div>
                            {props.subscriber?.firstName} {props.subscriber?.lastName}
                        </div>
                        <Row>
                            <div>{props.subscriber?.registeredAddress?.street}</div>, <Space right={5} />
                            <div>{props.subscriber?.registeredAddress?.postCode}</div>
                        </Row>
                        <Row>
                            <div>{props.subscriber?.registeredAddress?.city}</div>, <Space right={5} />
                            <div>{props.subscriber?.registeredAddress?.countryCode}</div> <Space right={5} />
                        </Row>
                    </>
                </Detail>
            )}
            <DetailsViewAll
                name={t('virtualAddressesDetails.Correspondence')}
                subTitle={t('virtualAddressesDetails.totalReceivedMails')}
                count={props.correspondences.count}
                viewAllAction={() =>
                    history.push({
                        pathname: navigationRoutes.user.correspondence,
                        state: historyApiState,
                    })
                }
            />
            <DetailsViewAll
                name={t('virtualAddressesDetails.utilityBills')}
                subTitle={t('virtualAddressesDetails.poaDocuments')}
                count={props.utilityBillCount}
                viewAllAction={() =>
                    history.push({
                        pathname: navigationRoutes.user.utilityBills,
                        state: historyApiState,
                    })
                }
            />
        </Gutter>
    )
}

export default Details
