import { Theme, useMediaQuery } from '@mui/material'
import { ContentBox, HeaderWithLogo, HeaderWithTextCircle } from 'components/shared'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { navigationRoutes } from 'routes/Routes'
import { Row } from 'styles'
import { dateToLocaleString, secondsToMonths } from 'utils'

interface IProps extends IUserSubscription {}

const Content: FC<IProps> = props => {
    const { t } = useTranslation()
    const matches = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'))
    const expiring = matches ? <Row>{`${t('virtualAddressContent.Expiring')}: ${dateToLocaleString(props.expiresAt)}`}</Row> : <></>
    const pendingPaymentLink = <Link to={navigationRoutes.user.payments}>Pending payment</Link>

    return (
        <ContentBox>
            <HeaderWithLogo text={`${t('virtualAddressContent.virtualAddress')}: ${props.duration?.totalSeconds ? secondsToMonths(props.duration?.totalSeconds) : 0} months`} />
            {props.status === 'Pending' ? pendingPaymentLink : expiring}
            <HeaderWithTextCircle textInCircle={`${props.correspondences.newCount}`} text={t('virtualAddressContent.newCorrespondence')} />
            {/* <HeaderWithTextCircle textInCircle={`${props.correspondences.scanningInProgressCount}`} text={t('virtualAddressContent.pendingPayment')} /> */}
        </ContentBox>
    )
}

export default Content
